<template>
  <div class="smenu">
  <div class="smenu level2" :class="{show: menu === 'default'}">
    <ul>
      <page-menu-item icon-hover label="Filas" icon="fal fa-list-alt fa-fw" @click.prevent.native="menu = 'fila'"/>
      <page-menu-item label="Registrar um pedido" icon="fal fa-plus-square fa-fw" @click.native="criarTarefa" />
      <page-menu-item label="Relatórios" icon="fal fa-chart-line fa-fw" :route="{name: 'suporte.tarefas.relatorios'}"/>
    </ul>
    <div class="menu-lbl">Conhecimento</div>
    <ul>
      <page-menu-item class="disabled" label="Base de conhecimento" icon="fal fa-database fa-fw"/>
    </ul>
<!--    <div class="menu-lbl">Atalhos</div>
    <ul>
      <page-menu-item label="Dashboard" icon="fal fa-clipboard-list fa-fw" :route="{name: 'console.suporte'}"/>
      <page-menu-item label="Projetos" icon="fal fa-book fa-fw fa-fw" :route="{name: 'suporte.projetos'}"/>
      <page-menu-item label="Clientes" icon="fal fa-user fa-fw" :route="{name: 'console.clientes'}"/>
    </ul>-->
  </div>
  <div class="smenu level2" :class="{show: menu === 'fila'}">
    <ul class="menu-back">
      <li><a @click="voltar"><i class="smenu-icon fas fa-arrow-circle-left fa-fw"></i> Voltar</a></li>
    </ul>
    <ul v-if="queues">
      <li class="sdesc">
        <h3>Filas</h3>
        <p>Filas ajudam a controlar um grupo de tarefas e sua ordem de atendimento</p>
      </li>
      <page-menu-item label="Em aberto" name="opened" :count="openeds || 0" />
      <page-menu-item label="Atribuídos a mim" name="assignedMe" :count="assignedMes || 0" />
      <page-menu-item label="Não atribuídos" name="unassigned" :count="unassigneds || 0" />
      <page-menu-item label="Atrasadas" name="due" :count="dues || 0" />
      <page-menu-item ref="closedMenu" label="Fechadas" name="closed" :count="closeds || 0" v-show="false" />
      <page-menu-item v-for="fila in queues" :name="'id:' + fila.id" :key="'fila-' + fila.id" :label="fila.name" :count="Number(fila._tasks)" />
      <li class="sdesc m-t">
        <p><a @click="$refs.closedMenu.link()" class="text-grey">Ver solicitações fechadas</a></p>
      </li>
      <!--<page-menu-item label="Melhorias" :count="0" />
      <page-menu-item label="Bugfix" :count="7" />
      <page-menu-item label="Suporte geral" :count="2" />
      <page-menu-item label="Customização geral" :count="1" />
      <page-menu-item label="Customização Premium" :count="23" />-->
    </ul>
  </div>
  </div>
</template>

<script>
import PageMenuItem from "components/suporte/components/include/PageMenuItem"
import criarTarefaWindow from "components/suporte/components/task/window/criarTarefa"

export default {
  name: "MenuTarefas",
  inject: ['page', 'container'],
  data () {
    return {
      menu: 'default'
    }
  },
  computed: {
    queues () {
      return this.container.queues
    },
    openeds () {
      return this.container.fixedQueues && this.container.fixedQueues.opened
    },
    assignedMes () {
      return this.container.fixedQueues && this.container.fixedQueues.assignedMe
    },
    unassigneds () {
      return this.container.fixedQueues && this.container.fixedQueues.unassigned
    },
    dues () {
      return this.container.fixedQueues && this.container.fixedQueues.due
    },
    closeds () {
      return this.container.fixedQueues && this.container.fixedQueues.closed
    }
  },
  components: {
    PageMenuItem,
  },
  mounted() {
    const node = document.getElementById('suporte-page-menu')
    node.append(this.$el)
  },
  beforeDestroy() {
    const node = document.getElementById('suporte-page-menu')
    node && node.contains(this.$el) && node.removeChild(this.$el)
  },
  methods: {
    criarTarefaWindow,
    criarTarefa () {
      this.criarTarefaWindow(null, null)
    },
    voltar () {
      this.menu = 'default'
    }
  }
}
</script>
